import { t } from "@lingui/macro";
import React, { useCallback, useContext, useEffect, useMemo } from "react";
import emoji_arm from "../../../static/images/emojis/arm.png";
import emoji_budget from "../../../static/images/emojis/budget.png";
import emoji_carb from "../../../static/images/emojis/carb.png";
import emoji_cook from "../../../static/images/emojis/cook.png";
import emoji_foods from "../../../static/images/emojis/foods.png";
import emoji_leftovers from "../../../static/images/emojis/leftovers.png";
import emoji_meals from "../../../static/images/emojis/meals.png";
import emoji_proteins from "../../../static/images/emojis/proteins.png";
import { useNavigate } from "../../components/Link/Link";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import { pageContext } from "../../components/PageProvider/PageProvider";
import { SEOReactHelmet } from "../../components/SEO/SEOReactHelmet";
import {
  carbLevelSelector,
  CarbLevelStep,
  getOptions as getCarbLevelOptions,
} from "../onboarding/steps/CarbLevelStep";
import {
  cookingSkillSelector,
  CookingSkillsStep,
  getOptions as getCookingSkillOptions,
} from "../onboarding/steps/CookingSkillStep";
import {
  cookingTimeSelector,
  CookingTimeStep,
  getOptions as getCookingTimeOptions,
} from "../onboarding/steps/CookingTimeStep";
import {
  cuisineInterestSelector,
  CuisineInterestStep,
  getOptions as getCuisineInterestOptions,
} from "../onboarding/steps/CuisineInterestStep";
import {
  dietChallengeSelector,
  DietChallengesStep,
  getOptions as getDietChallengesOptions,
} from "../onboarding/steps/DietChallengesStep";
import {
  foodBudgetSelector,
  FoodBudgetStep,
  getOptions as getFoodBudgetOptions,
} from "../onboarding/steps/FoodBudgetStep";
import {
  foodPreferenceSelector,
  FoodPreferencetStep,
  getOptions as getFoodPreferencesOptions,
} from "../onboarding/steps/FoodPreferenceStep";
import {
  foodsToAvoidSelector,
  FoodsToAvoidStep,
  getOptions as getFoodsToAvoidOptions,
} from "../onboarding/steps/FoodsToAvoidStep";
import {
  getOptions as getMealsPerDayOptions,
  mealPerDaySelector,
  MealsPerDayStep,
} from "../onboarding/steps/MealsPerDayStep";
import {
  getOptions as getProteinLevelOptions,
  proteinLevelSelector,
  ProteinLevelStep,
} from "../onboarding/steps/ProteinLevelStep";
import {
  dietSelector,
  getOptions as getProteinsToAvoidOptions,
  proteinsToAvoidSelector,
  ProteinsToAvoidStep,
} from "../onboarding/steps/ProteinsToAvoidStep";
import { useOnboardingTranslations } from "../onboarding/translations";
import { CarbLevel, OnboardingStepProps } from "../onboarding/types";
import { LoginPage } from "../../pages";
import { isBrowser } from "../../utils/ssr";
import { PreferencePortal, PreferenceSetting } from "./PreferenceSetting";

import { useActor, useMachine, useSelector } from "@xstate/react";
import { assign, createMachine } from "xstate";
import { OnboardingStateContext, OnboardingStateProvider } from "../onboarding/OnboardingStateProvider";
import { leftOversSelector, LeftOversStep } from "../onboarding/steps/LeftOversStep";
import { Diet, FoodType } from "../../types/graphql-global-types";
import { UpdatePreferencesNote } from "./UpdatePreferencesNote";

const machine = createMachine(
  {
    predictableActionArguments: true,
    initial: "idle",
    context: {
      step: undefined,
    },
    states: {
      idle: {
        on: {
          EDIT: {
            target: "editing",
            actions: ["edit"],
          },
        },
      },
      editing: {
        on: {
          DONE: "idle",
        },
      },
    },
  },
  {
    actions: {
      edit: assign((_, e: any) => ({
        step: e.step,
      })),
    },
  }
);

// ts-prune-ignore-next
export default function FoodPreferencesPage() {
  if (!isBrowser()) {
    return null;
  }

  const { locale } = useContext(pageContext);
  const tt = useTranslations();

  return (
    <>
      <SEOReactHelmet title={tt.seoTitle} description={tt.seoDescription} lang={locale as string} />
      <OnboardingStateProvider>
        <FoodPreferencesPageComponent />
      </OnboardingStateProvider>
    </>
  );
}

function FoodPreferencesPageComponent() {
  const onboarding = useContext(OnboardingStateContext);
  const [, sendToOnboardingMachine] = useActor(onboarding.machine!);

  const cookingTime = useSelector(onboarding.machine!, cookingTimeSelector);
  const proteinsToAvoid = useSelector(onboarding.machine!, proteinsToAvoidSelector);
  const foodsToAvoid = useSelector(onboarding.machine!, foodsToAvoidSelector);
  const carbLevel = useSelector(onboarding.machine!, carbLevelSelector);
  const cookingSkill = useSelector(onboarding.machine!, cookingSkillSelector);
  const mealsPerDay = useSelector(onboarding.machine!, mealPerDaySelector);
  const cuisineInterest = useSelector(onboarding.machine!, cuisineInterestSelector);
  const desiredDishes = useSelector(onboarding.machine!, foodPreferenceSelector);
  const foodBudget = useSelector(onboarding.machine!, foodBudgetSelector);
  const dietChallenge = useSelector(onboarding.machine!, dietChallengeSelector);
  const proteinLevel = useSelector(onboarding.machine!, proteinLevelSelector);
  const leftOvers = useSelector(onboarding.machine!, leftOversSelector);
  const diet = useSelector(onboarding.machine!, dietSelector);

  const [state, send] = useMachine(machine);
  const isEditing = useMemo(() => state.matches("editing"), [state]);
  const StepUI = useMemo(() => state.context.step, [state]);
  const navigate = useNavigate();
  const { loading: authLoading, user: userData } = useContext(LoginContext);

  useEffect(() => {
    if (!authLoading && !userData) {
      navigate({ to: LoginPage, query: { redirectTo: window.location.href } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authLoading]);

  // const { preferences, updateUserPreferences } = useUserPreferences()
  const tt = useTranslations();
  const ott = useOnboardingTranslations();

  const avoidedFoodsValue = useMemo(() => {
    const arr = foodsToAvoid?.filter(onlyOtherTypesOfFood);
    return arr?.length === 0
      ? ott.buttonNone
      : arr?.map((x) => getFoodsToAvoidOptions(ott).find((o) => o.option === x)?.label).join(", ") ??
          ott.buttonNone;
  }, [ott, foodsToAvoid]);

  const avoidedProteinsValue = useMemo(() => {
    const arr = proteinsToAvoid?.filter(onlyProteinOfFood);
    return arr?.length === 0
      ? diet === Diet.VEGETARIAN
        ? ott.proteinToAvoidStep.answerVegetarian
        : ott.buttonNone
      : arr?.map((x) => getProteinsToAvoidOptions(ott).find((o) => o.option === x)?.label).join(", ");
  }, [diet, proteinsToAvoid, ott]);

  const carbToleranceValue = useMemo(
    () => getCarbLevelOptions(ott).find((x) => x.option === (carbLevel as unknown as CarbLevel))?.label,
    [ott, carbLevel]
  );
  const cookingSkillValue = useMemo(
    () => getCookingSkillOptions(ott).find((x) => x.option === cookingSkill)?.label,
    [ott, cookingSkill]
  );
  const cookingTimeExtentValue = useMemo(
    () => getCookingTimeOptions(ott).find((x) => x.option === cookingTime)?.label,
    [ott, cookingTime]
  );
  const dailyMealsCountValue = useMemo(
    () => getMealsPerDayOptions(ott).find((x) => x.option === mealsPerDay?.toString())?.label,
    [ott, mealsPerDay]
  );
  const desiredCuisinesValue = useMemo(
    () =>
      cuisineInterest
        ?.map((x) => getCuisineInterestOptions(ott).find((o) => o.option === x)?.label)
        .join(", ") ?? ott.buttonNone,

    [ott, cuisineInterest]
  );
  const desiredDishesValue = useMemo(
    () =>
      desiredDishes?.map((x) => getFoodPreferencesOptions(ott).find((o) => o.option === x)?.label).join(", ") ??
      ott.buttonNone,
    [ott, desiredDishes]
  );
  const foodBudgetValue = useMemo(
    () => getFoodBudgetOptions(ott).find((x) => x.option === foodBudget)?.label,
    [ott, foodBudget]
  );
  const dietChallengeValue = useMemo(
    () => getDietChallengesOptions(ott).find((x) => x.option === dietChallenge)?.label,
    [ott, dietChallenge]
  );
  const proteinLevelValue = useMemo(
    () => getProteinLevelOptions(ott).find((x) => x.option === proteinLevel)?.label,
    [ott, proteinLevel]
  );
  const leftoverValue = useMemo(
    () => (leftOvers === true ? ott.leftOversStep.answerYes : ott.leftOversStep.answerNo),
    [ott.leftOversStep.answerNo, ott.leftOversStep.answerYes, leftOvers]
  );

  const onEdit: (step: React.FunctionComponent<OnboardingStepProps>) => () => void = useCallback(
    (step) => () => {
      send({ type: "EDIT", step });
    },
    [send]
  );

  const onDone = useCallback(() => {
    send({ type: "DONE" });
    sendToOnboardingMachine({ type: "UPDATE_USER_PREFERENCES" });
  }, [send, sendToOnboardingMachine]);

  return (
    <>
      {isEditing && <PreferencePortal PreferenceUI={StepUI} onDone={onDone} />}
      <div className="max-w-screen-xl mx-auto w-full px-6 pb-24">
        <section className="w-full mt-l ">
          <div className="flex flex-row justify-between items-center">
            <h2 className="mx-0 my-8">{tt.settingsTitle}</h2>
          </div>
        </section>
        <section className="w-full mt-l ">
          <ul className="p-0 flex flex-col w-full gap-8">
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_meals}
                title={ott.cuisineInterestStep.question}
                value={desiredCuisinesValue}
                onEdit={onEdit(CuisineInterestStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_meals}
                title={ott.foodPreferenceStep.question}
                value={desiredDishesValue}
                onEdit={onEdit(FoodPreferencetStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_arm}
                title={ott.proteinLevelStep.question}
                value={proteinLevelValue}
                onEdit={onEdit(ProteinLevelStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_proteins}
                title={ott.foodsToAvoidStep.question}
                value={avoidedFoodsValue}
                onEdit={onEdit(FoodsToAvoidStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_proteins}
                title={ott.proteinToAvoidStep.question}
                value={avoidedProteinsValue}
                onEdit={onEdit(ProteinsToAvoidStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_budget}
                title={ott.foodBudgetStep.question}
                value={foodBudgetValue}
                onEdit={onEdit(FoodBudgetStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_cook}
                title={ott.cookingTimeStep.question}
                value={cookingTimeExtentValue}
                onEdit={onEdit(CookingTimeStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_cook}
                title={ott.cookingSkillsStep.question}
                value={cookingSkillValue}
                onEdit={onEdit(CookingSkillsStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_carb}
                title={ott.carbLevelStep.question}
                value={carbToleranceValue}
                onEdit={onEdit(CarbLevelStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_foods}
                title={ott.dietChallengesStep.question}
                value={dietChallengeValue}
                onEdit={onEdit(DietChallengesStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_leftovers}
                title={ott.mealsPerDayStep.question}
                value={dailyMealsCountValue}
                onEdit={onEdit(MealsPerDayStep)}
              />
            </li>
            <li className="flex w-full border-b border-b-grey-100 pb-4">
              <PreferenceSetting
                icon={emoji_leftovers}
                title={ott.leftOversStep.question}
                value={leftoverValue}
                onEdit={onEdit(LeftOversStep)}
              />
            </li>
          </ul>
        </section>
        <section>
          <UpdatePreferencesNote />
        </section>
      </div>
    </>
  );
}

function useTranslations() {
  return {
    seoTitle: t({
      id: "FoodPreferences.seoTitle",
      message: "Food preferences - Diet Doctor",
    }),
    seoDescription: t({
      id: "FoodPreferences.seoDescription",
      message: "Food preferences",
    }),
    settingsTitle: t({
      id: "FoodPreferences.settingsTitle",
      message: "Food preferences",
    }),
  };
}

function onlyOtherTypesOfFood(food: FoodType) {
  switch (food) {
    case FoodType.EGGS:
    case FoodType.NUTS:
    case FoodType.DAIRY:
      return true;
    default:
      return false;
  }
}

function onlyProteinOfFood(food: FoodType) {
  switch (food) {
    case FoodType.BEEF:
    case FoodType.FISH:
    case FoodType.LAMB:
    case FoodType.PORK:
    case FoodType.POULTRY:
    case FoodType.SHELLFISH:
      return true;
    default:
      return false;
  }
}
